import styled from 'styled-components';

const Box = styled.div`
    box-sizing: border-box;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    border: ${(props) => props.border};
    margin: ${(props) => props.margin};
    width: ${(props) => props.width};
    text-align: ${(props) => props.textAlign};

    p:last-child,
    ol:last-child,
    ul:last-child {
        margin-bottom: 0;
    }
`;

Box.defaultProps = {
    background: 'transparent',
};

export default Box;
