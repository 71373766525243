const theme = {
    colors: {
        principalGreen: '#48a383',
        darkGreen: '#12af7f',
        lightGreen: '#c3f1e1',
        white: '#fff',
        black: '#020a2c',
        red: '#e6555f',
        purple: '#5864fe',
        grey: {
            90: '#f9f9f9',
            100: '#f1f2f4',
            110: '#f9fafe',
            200: '#e7e8eb',
            300: '#a2a6b1',
            310: '#b9bccb',
            400: '#5a6073',
            500: '#585d72',
        },
    },
    fontSizes: {
        extraSmall: '0.875rem',
        small: '0.9375rem',
        medium: '1.5625rem',
        large: '2rem',
    },
    fonts: {
        primary: "'Inter', sans-serif",
        heading: "'Futura PT', sans-serif",
        base: "'Inter', sans-serif",
    },
    spaces: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

export default theme;
