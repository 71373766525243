import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name on the Screen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First name of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rajesh`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Surname or last name of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Powar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of Birth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Birth date of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1981-02-22`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Male, Female, Non_Binary, Not_Stated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Emp. Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employment type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Unspecified, Permanent_FullTime, Permanent_PartTime, `}<br />{`Temporary, Casual, Contract, FixedTerm_FullTime, `}<br />{`FixedTerm_PartTime, LabourHire, SuperIncomeStream`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the branch where employee is registered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brighton Store`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent branch of employee’s branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – /Trumps incorporated/Brighton Store`}<br />{`If not exists - /Brighton Store`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Current employment status of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Current, Terminated, Completed, Suspended, Leave, `}<br />{`LeaveWithoutPay, Transferred, Terminating, Merged`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate to Employee (NA)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the Candidate has been converted to an employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2022-02-03T19:20:30.45+01:00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E-mail (W)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Work email of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EmailWork`}<span>{`@`}</span>{`email.com`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E-mail (H)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Personal email of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EmailHome`}<span>{`@`}</span>{`email.com`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reports To ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the Manager`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4eb62cb48c9f429c93bc22564c1a5a4d`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      