import Message from "../../../../src/enb-styled/Message.js";
import TableMaintainEmployeeBankDetails from "../../../../src/markdowns/sections/docs/payroll/parameters-maintain-employee-bank-details.md";
import TableRetrieveEmployeeBankAccounts from "../../../../src/markdowns/sections/docs/payroll/response-retrieve-employee-bank-details.md";
import TableMaintainEmployeeSuperAnnuation from "../../../../src/markdowns/sections/docs/payroll/parameters-maintain-employee-superannuation.md";
import TableRetrieveEmployeeSuperAnnuation from "../../../../src/markdowns/sections/docs/payroll/response-retrieve-employee-superannuation.md";
import TableMaintainEmployeeTaxDetails from "../../../../src/markdowns/sections/docs/payroll/parameters-maintain-employee-tax-details.md";
import TableRetrieveEmployeeTaxDetails from "../../../../src/markdowns/sections/docs/payroll/response-retrieve-employee-tax-details.md";
import TableRetrieveEmployeeLeaveBalances from "../../../../src/markdowns/sections/docs/payroll/response-retrieve-employee-leave-balances.md";
import TableMaintainEmployeeLeaveBalances from "../../../../src/markdowns/sections/docs/payroll/parameters-maintain-employee-leave-balances.md";
import TableRetrieveEmployeePayrollDetails from "../../../../src/markdowns/sections/docs/payroll/response-retrieve-employee-payroll-details.md";
import TableMaintainEmployeePayrollDetails from "../../../../src/markdowns/sections/docs/payroll/parameters-maintain-employee-payroll-details.md";
import * as React from 'react';
export default {
  Message,
  TableMaintainEmployeeBankDetails,
  TableRetrieveEmployeeBankAccounts,
  TableMaintainEmployeeSuperAnnuation,
  TableRetrieveEmployeeSuperAnnuation,
  TableMaintainEmployeeTaxDetails,
  TableRetrieveEmployeeTaxDetails,
  TableRetrieveEmployeeLeaveBalances,
  TableMaintainEmployeeLeaveBalances,
  TableRetrieveEmployeePayrollDetails,
  TableMaintainEmployeePayrollDetails,
  React
};