import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name on the Screen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`List of Branches in the account (array)`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39c4e9b0418e4236b6a8ac4bce927a9e`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch Name as per the setup`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brighton Store`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hierarchical position of the branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – /Trumps incorporated/Brighton Store`}<br />{`If not exists - /Brighton Store`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of branch setup in enableHR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allow values: Legal Entity, Department`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Parent Branch Details (If exists)`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent Branch - ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the parent branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`e15b0e829753458095cf3e54ed967ac5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent Branch - Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the parent branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Trumps incorporated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent Branch - Path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hierarchical position of the parent branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Trumps incorporated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Child Branch Details (If exists)`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Child Branch - ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the Child branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Child Branch - Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the the Child branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Child Branch - Path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hierarchical position of the Child branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Branch address`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line1:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 1 of the Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`132 Test Street`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line2:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 2 of the Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb of the Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Melbourne`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State where the suburb is located`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VIC`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Post Code:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Postal code of the Suburb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the Suburb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australia`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      