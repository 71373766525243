import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name on the Screen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For Australian Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of SuperFund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REGULATED or SMSF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee/member number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Unique Member number for the super fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`98001`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fund name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Superannuation fund name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Super fund test`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ABN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid Australian Business Number of the fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65714394898`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Electronic Service Address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Electronic Service Address for data messages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ESUPERFUND`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Super Fund ID (USI)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Superannuation Identifier for the fund`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`STA0100AU`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank Account Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank Account name for SMSF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SMSF Fund name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank Account BSB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSB for SMSF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`016 789`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank Account Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank Account number for SMSF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`654587787`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For New Zealand Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Kiwi Saver status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accepted values - ACTIVE, OPT_OUT,`}<br />{`SAVINGS_SUSPENSION, NOT_A_MEMBER`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employer Contribution Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kiwi Super contribution made by the employer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee Contribution Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kiwi Super contribution made by the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3% (allowed values 3, 4, 6, 8, 10)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ESCT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employer Contribution tax based on earnings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17.5% (allowed values: 10.5, 17.5, 30, 33)`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      