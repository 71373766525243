import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Name - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Store Managers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A short description of the Role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Competencies necessary for the Store Manager`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional tag on the training role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Managers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#group-id"
            }}>{`Group ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the role group this role is assigned to.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42e289346bf14ef68cd826ab86336e8b`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      