import Message from "../../../../src/enb-styled/Message.js";
import TableListCompentencies from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-list-qualifications.md";
import TableCreateCompentency from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-create-competency.md";
import TableRetrieveCompentency from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-retrieve-competency.md";
import TableUpdateCompentency from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-update-competency.md";
import TableAssignCompentency from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-assign-competency.md";
import TableListAllocatedRoleCompentencies from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-list-allocated-role-competencies.md";
import TableAllocateEmployeeCompentencies from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-allocate-competency-employee.md";
import TableRetrieveEmployeeCompentency from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-retrieve-competency-employee.md";
import TableUpdateEmployeeCompentency from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-update-competency-employee.md";
import TableListRoles from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-list-roles.md";
import TableRetrieveRole from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-retrieve-role.md";
import TableCreateRole from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-create-role.md";
import TableUpdateRole from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-update-role.md";
import TableAllocateRoleEmployee from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-allocate-role-employee.md";
import TableListGroups from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-list-groups.md";
import TableCreateGroup from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-create-group.md";
import TableRetieveGroup from "../../../../src/markdowns/sections/docs/training-and-qualifications/response-retrieve-group.md";
import TableUpdateGroup from "../../../../src/markdowns/sections/docs/training-and-qualifications/parameters-update-group.md";
import * as React from 'react';
export default {
  Message,
  TableListCompentencies,
  TableCreateCompentency,
  TableRetrieveCompentency,
  TableUpdateCompentency,
  TableAssignCompentency,
  TableListAllocatedRoleCompentencies,
  TableAllocateEmployeeCompentencies,
  TableRetrieveEmployeeCompentency,
  TableUpdateEmployeeCompentency,
  TableListRoles,
  TableRetrieveRole,
  TableCreateRole,
  TableUpdateRole,
  TableAllocateRoleEmployee,
  TableListGroups,
  TableCreateGroup,
  TableRetieveGroup,
  TableUpdateGroup,
  React
};