import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Branch ID - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch information of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of branch: 39c4e9b0418e4236b6a8ac4bce927a9e`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Honorific of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Mr, Mrs, Ms, Miss, Dr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`First Name - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First name of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rajesh`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Last Name - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Surname or last name of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Powar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Middle Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Middle name of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Preferred Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee’s preferred name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Raj`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nick Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short name called at workplace`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Raj`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Personal address`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line1:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 1 of the address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suite 3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line2:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 2 of the address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1234 Pacific Highway`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sydney Town`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State where the suburb is located`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NSW`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Post Code:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Postal code of the suburb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2002`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the suburb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australia`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee number allocated to the associate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12345`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Postal (mailing) address`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line1:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 1 of the postal address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suite 3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line2:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 2 of the postal address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1234 Pacific Highway`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sydney Town`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State where the suburb is located`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NSW`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Post Code:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Postal code of the suburb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2002`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the suburb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australia`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Contact details`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email (W)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Work Email address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RajeshP`}<span>{`@`}</span>{`email.com`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email (h)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Personal Email address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P_Rajmaheh`}<span>{`@`}</span>{`email.com`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number (h)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Home Phone number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`08 4044 0999`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Personal mobile phone number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0404 404 040`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Male, Female, Non_Binary, Not_Stated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of Birth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Birthdate of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2000-12-29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Employment details`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Position Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Designation of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Storekeeper`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique number assigned by the company.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0064`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employment Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nature of employment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Supported Values: Unspecified, Permanent_FullTime, `}<br />{`Permanent_PartTime, Temporary, Casual, `}<br />{`Contract, FixedTerm_FullTime, FixedTerm_PartTime, `}<br />{`LabourHire, SuperIncomeStream`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employment Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New employment status of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Current, Completed, `}<br />{`Suspended, Leave, LeaveWithoutPay, `}<br />{`Terminating, Merged`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Commencement Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date of employment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-09-22`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Emergency Contact details`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Primary`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary emergency contact’s name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Steve Smith`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Relationship`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Relationship to the employee/candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brother`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the primary contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03 5678 9876`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile number of the primary contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04 1111 2222`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Secondary`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Secondary emergency contact’s name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Diane Hayden`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Relationship`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Relationship to the employee/candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mother`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the secondary contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03 1123 2244`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile number of the secondary contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04 6666 7777`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      