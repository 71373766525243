import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name `}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Name - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forklift License`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A short description of the Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is a mandatory requirement for the Employees who work in certain jobs.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed values: Qualification, Training, Skill,`}<br />{`ProfessionalRegistrationMembership, LicenceTicket,`}<br />{`Medical, Capability, Unspecified`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Default issuing authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority responsible for issuing this Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Service NSW`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Points`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of points allocated for holding this Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A short text to describe Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`License`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Default Date completed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`By when the Competency is to be achieved`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2023-12-31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expires after - value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Competency expires after a certain duration (see next field)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expires after - unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed values: Days, Weeks, Months, Years`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#group-id"
            }}>{`Group ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the Group for this Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42e289346bf14ef68cd826ab86336e8b`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      