import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name on the Screen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique number assigned to the candidate by the user creating the candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14345`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First name of the candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Michael`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Surname or last name of the candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Johnson`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of Birth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Birthdate of the candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1978-09-28`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender of the candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Male, Female, Non_Binary, Not_Stated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Position Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Position title of the candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Manager`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WkDays in System`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of weekdays (Monday-Friday) the candidate has been in the system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the candidate profile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Current, Not Current`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch under which the candidate exists`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brighton Store`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent branch of candidate’s branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – /Trumps Inc/Brighton Store.`}<br />{`If doesn’t exist - /Brighton Store.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      