import { css } from 'styled-components';
import React from 'react';
import Box from './Box';

const Message = (props) => {
    return (
        <Box
            {...props}
            css={css`
                padding: ${({ theme }) => theme.spaces[3]}px;
                border-left: ${({ theme }) => theme.spaces[1]}px;
                border-left-style: solid;
                border-left-color: ${({ theme }) => theme.colors.principalGreen};
                border-radius: ${({ theme }) => theme.spaces[1]}px;
                background: ${({ theme }) => theme.colors.grey['90']};
                font-size: ${({ theme }) => theme.fontSizes.extraSmall};
            `}
        />
    );
};

export default Message;
