import Message from "../../../../src/enb-styled/Message.js";
import TableListEmployees from "../../../../src/markdowns/sections/docs/employees/response-list-employees.md";
import TableRetrieveEmployee from "../../../../src/markdowns/sections/docs/employees/response-retrieve-employee.md";
import TableCreateEmployee from "../../../../src/markdowns/sections/docs/employees/request-create-employee.md";
import TableUpdateEmployee from "../../../../src/markdowns/sections/docs/employees/request-update-employee.md";
import TableTransferEmployee from "../../../../src/markdowns/sections/docs/employees/request-transfer-employee.md";
import TableTerminateEmployee from "../../../../src/markdowns/sections/docs/employees/request-terminate-employee.md";
import * as React from 'react';
export default {
  Message,
  TableListEmployees,
  TableRetrieveEmployee,
  TableCreateEmployee,
  TableUpdateEmployee,
  TableTransferEmployee,
  TableTerminateEmployee,
  React
};