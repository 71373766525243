import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name on the Screen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employees number as stored in enableHR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12345`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First name of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Michael`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Surname or last name of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Johnson`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of Birth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Birthdate of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2001-02-01`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employment Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employment type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Unspecified, Permanent_FullTime, Permanent_PartTime, `}<br />{`Temporary, Casual, Contract, FixedTerm_FullTime, `}<br />{`FixedTerm_PartTime, LabourHire, SuperIncomeStream`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the branch where the employee is registered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brighton Store`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent Branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent branch of employee’s branch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – /Trumps incorporated/Brighton Store`}<br />{`If not exists - /Brighton Store`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Current employment status of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Current, Terminated, Completed, Suspended, `}<br />{`Leave, LeaveWithoutPay, Transferred, Terminating, Merged`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Honorific of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Mr, Mrs, Ms, Miss, Dr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Middle Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Middle name of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Preferred Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee’s preferred name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mike`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nick Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short name called at workplace`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mickey`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reports To ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the Manager`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4eb62cb48c9f429c93bc22564c1a5a4d`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Personal address`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line1:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 1 of the address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suite 3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line2:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 2 of the address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1234 Pacific Highway`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sydney Town`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State where the suburb is located`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NSW`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Post Code:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Postal code of the suburb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2002`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country the suburb belongs to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australia`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Postal (mailing) address`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line1:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 1 of the postal address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suite 3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line2:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line 2 of the postal address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1234 Pacific Highway`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suburb of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sydney Town`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State where the suburb is located`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NSW`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Post Code:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Postal code of the suburb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2002`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country the suburb belongs to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australia`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Contact details`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email (W)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Work email address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MichaelJ`}<span>{`@`}</span>{`email.com`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email (h)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Personal email address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MichaelJ`}<span>{`@`}</span>{`email.com`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number (h)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Home phone number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`08 4044 0999`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Personal mobile phone number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0404 404 040`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Male, Female, Non_Binary, Not_Stated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Employment details`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Position Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Designation of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Manager`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Commencement Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hire/Start date of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2015-09-23`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Termination date of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-10-15`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate to Employee (NA)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the Candidate has been converted to an employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2022-02-03T19:20:30.45+01:00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Emergency Contact details`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Primary`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary emergency contact’s name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Steve Smith`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Relationship`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Relationship to the employee/candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brother`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the primary contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03 5678 9876`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile number of the primary contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04 1111 2222`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Secondary`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Secondary emergency contact’s name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Diane Hayden`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Relationship`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Relationship to the employee/candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mother`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the secondary contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03 1123 2244`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile number of the secondary contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04 6666 7777`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      