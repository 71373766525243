import React from 'react';
import SEO from './seo';
import Header from './header';
import Sidebar from './sidebar/sidebar';
import Footer from './footer';
import Top from './top';
import 'react-tabs/style/react-tabs.css';
import '../styles/app.scss';
import GlobalStyle from '../theme/globalStyles';
import { getSidebarItems } from '../utils/sidebar/item-list';

const Layout = (props) => {
    const renderContent = (props) => {
        const items = sidebarItems(props);
        const displaySidebar = items ? true : false;
        return (
            <div className="container-fluid">
                <div className="row">
                    {displaySidebar && (
                        <div className="col-md-3 col-xl-2 bd-sidebar">
                            <Sidebar {...props} items={items} />
                        </div>
                    )}

                    <div
                        className={
                            displaySidebar
                                ? 'col-md-9 col-xl-9 bd-content position-relative'
                                : 'col-md-12 col-xl-12 bd-content'
                        }>
                        {props.children}
                        <Top />
                    </div>
                </div>
            </div>
        );
    };

    const renderFooter = () => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 col-xl-12 py-md-3 pl-md-5 bd-content">
                    <Footer />
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <GlobalStyle />
            <SEO {...props} />
            <Header />
            {renderContent(props)}
            {renderFooter()}
        </div>
    );
};

export default Layout;

/**
 * Retrieve the sidebar items which are defined in yaml
 * @param {Object} props
 * @returns {Object|undefined}
 */
function sidebarItems(props) {
    const path = props ? (props['*'] ? props['*'] : props.path ? props.path : ``) : ``;
    const section = path.split('/').filter((item) => item.length)[0];
    const sidebarObject = getSidebarItems(section);
    return sidebarObject.items;
}
