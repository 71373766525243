import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name on the Screen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#group-id"
            }}>{`Group ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42e289346bf14ef68cd826ab86336e8b`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Driving Licenses`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values: Role, Competency`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#group-id"
            }}>{`Parent Group Id`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the Parent Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – cc27e24987434ee0bb39d410937263c7`}<br />{`If doesn’t exist – null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent Group Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Parent Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – Licenses`}<br />{`If doesn’t exist – null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Child Groups (array)`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#group-id"
            }}>{`Child Group Id`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the Child Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – cc27e24987434ee0bb39d410937299d4`}<br />{`If doesn’t exist – null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Child Group Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Child Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – Forklift Licences`}<br />{`If doesn’t exist – null`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      