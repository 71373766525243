import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name `}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger"><a parentName="td" {...{
                "href": "#competency-id"
              }}>{`Competency ID`}</a>{` - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`b5431414c5bb41caa7f79dd4b17221b4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Status - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Competency status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed Values: In_Progress, Completed, Inactive, New_Requirement, Missing,`}<br />{`Expired, Not_Applicable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Issuing Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issuing Authority for the Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australian Training Institute`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Issuer Identifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issuer identity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Training Provider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date Completed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the Competency is completed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-08-31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expiry Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date the Competency expires`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2022-09-29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Points`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Points allocated for this Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      