import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import device from '../theme/device';

const NavLink = ({ to, text }) => (
    <li className="nav-item">
        <Link to={to} className="nav-link" activeClassName="current-page">
            {text}
        </Link>
    </li>
);

const StyledHeader = styled.header`
    min-height: 4rem;
    padding: 0 2em;
    background: ${({ theme }) => theme?.colors?.principalGreen};
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
    @media ${device.mobile} {
        padding: 0 20px;
    }
`;

const StyledBrandLink = styled((props) => <Link {...props} />)`
    margin-right: 4em;
`;

const ImageWrapper = styled.img`
    max-width: 120px;
`;

const WrapperNavLinks = styled.div`
    display: flex;
    @media ${device.mobile} {
        display: ${({ open }) => (open ? 'flex' : 'none')};
        flex-direction: row;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: ${({ theme }) => theme?.colors?.principalGreen};
        padding: 40px 32px;
        z-index: 10;
    }
`;

const NavLinks = () => (
    <ul className="navbar-nav">
        <NavLink to="/" text="Home" />
        <NavLink to="/docs/" text="Documentation" />
        <NavLink to="/development/" text="Development" />
        <NavLink to="/faq/" text="FAQ" />
        <NavLink to="/contact-us/" text="Contact Us" />
    </ul>
);

const StyledBurger = styled.div`
    display: none;
    @media ${device.mobile} {
        width: 2rem;
        height: 2rem;
        position: fixed;
        top: 15px;
        right: 20px;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        z-index: 20;
        div {
            width: 2rem;
            height: 0.24rem;
            background-color: ${({ theme, open }) => (open ? theme.colors.grey['100'] : theme?.colors?.white)};
            border-radius: 3px;
            transform-origin: 1px;
            &:nth-child(1) {
                transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
            }
            &:nth-child(2) {
                transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
                opacity: ${({ open }) => (open ? 0 : 1)};
            }
            &:nth-child(3) {
                transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
            }
        }
    }
`;

const HeaderNavigation = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
            </StyledBurger>
            <WrapperNavLinks open={open} onClick={() => setOpen(false)}>
                <NavLinks />
            </WrapperNavLinks>
        </>
    );
};

const Header = () => (
    <StyledHeader className="navbar navbar-expand bd-navbar">
        <StyledBrandLink to="/" className="navbar-brand" aria-label="enableHR">
            <ImageWrapper src="/images/EnableHR_ID_colour_black.svg" title="enableHR" alt="enableHR" />
        </StyledBrandLink>
        <div className="navbar-nav-scroll">
            <HeaderNavigation />
        </div>
    </StyledHeader>
);

export default Header;
