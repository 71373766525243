const size = {
    mobile: '767.98px',
    tablet: '991.98px',
};

const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
};

export default device;
