import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For Australian Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Annual Leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Annual leave units stored in enableHR for the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`480.23`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Personal Leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Personal leave units`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`220.00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Long Service Leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Long Service leave units`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13.00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For New Zealand Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Annual Leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Annual leave units stored in enableHR for the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23.42`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sick Leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sick leave units`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12.32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Long Service Leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Long Service leave units`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Domestic Violence leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domestic Violence leave units`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bereavement leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bereavement leave units provided to employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16.0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parental leave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parental leave units provided to employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alternative Holidays`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any alternative leave provided to employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      