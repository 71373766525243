import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name `}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suman Prabhat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Note Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the Note`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N000271`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Created`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date & Time when the note was added`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2019-07-16T19:35:55.45+01:00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of note`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comment, Email etc.,`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration in Seconds`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`142`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Start Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start time of the note creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2019-07-16T19:35:55.45+01:00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End time of the note creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2019-07-16T19:45:55.45+01:00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Category`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Topic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Topic for creating the note`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sensitive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is this sensitive note?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes/No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Regarding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reason why the note is created`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reason...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Comment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Link this note to another record`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      