import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For Australian Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`TFN - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tax file number of an employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid 9 digits of the TFN as enableHR does validations.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exempt Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any tax exemption based on age`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values – Unquoted, Pending, Under 18, Pensioner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee Basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of Employment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values – Full Time, Part Time, Casual, Labor Hire, Super Income stream`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Residency Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Residency for tax purposes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values – Australian Resident, Foreign Resident, Working Holiday Maker`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tax Free Threshold Claimed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employees option whether to be taxed for every dollar earned or not`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Trade Support Loan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Support for apprentices to assist with everyday costs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hecs Supported`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Higher Education Support from Australian government`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Student startup loan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voluntary loan for eligible students who get youth allowance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Financial Supplement Debt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Financing scheme offered by the Australian government's Social Security office`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FinAustralian Resident`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australian Resident for Tax Purposes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Eligible To Receive Leave Loading`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`As outlined in your employee's award or workplace agreement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Other Tax Offset Claimed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reduces the tax on the taxable income for Low- and Middle-income earner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Upward Variation Requested`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employees request to increase the PAYG deducted from their pay each week or fortnight to avoid a bill from the ATO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved Withholding Variation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employees have necessary approval from ATO for withholding variation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved Withholding Variation Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage value for the withholding variation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Income Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employment Income`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values – Salary and Wages, Closely held payees, Working holiday maker`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee's country of Origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of Origin when the employee is a foreigner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AR - Argentina`}<br />{`Valid ISO 3166-1 alpha-2 country code, e.g. "AU", "NZ", "CA", or an empty string ("") to unset a previously set value. `}<a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Current_codes" target="_blank">{`ISO 3166-1 alpha-2`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tax File Declaration signed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the employee signed TFN declaration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2001-02-01`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tax Scale type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee Tax Scale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Regular, Horticulturists and shearers, 'Actors, variety artists and other entertainers', Seniors and pensioners, Working holiday maker, Foreigner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee's working condition`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When 'Tax Scale Type' selected is ‘Actors, variety artists and other entertainers'`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Promotional, 3 or less performances per week, None of these`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Senior's Marital Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When 'Tax Scale Type' selected is 'Seniors and pensioners'`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values: Couple, Illness Seperated Couple, Single`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Study and Training Support Loan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Loans taken from Government for higher education, trade apprenticeships and other training programs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For New Zealand Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Tax Code - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tax codes help the employer work out how much tax to deduct`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed values as per the `}<a parentName="td" {...{
              "href": "https://www.ird.govt.nz/income-tax/income-tax-for-individuals/tax-codes-and-tax-rates-for-individuals/tax-codes-for-individuals/work-out-my-tax-code"
            }}>{`IRD Spec`}</a>{`: (CAE, M, M_SL, ME, ME_SL, ND, NSW, S, S_SL, SA, SA_SL, SB, SB_SL, SH, SH_SL, ST, ST_SL, STC, WT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IRD Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique number issued by Inland Revenue to customers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 or 9-digit number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Student Loan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4%`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      