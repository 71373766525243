import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name `}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#competency-id"
            }}>{`Competency ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`dc56c0e53854462a8cfa23e27e6c6c71`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First Aid certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A short description of the Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First Aid certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed values: Qualification, Training, Skill,`}<br />{`ProfessionalRegistrationMembership, LicenceTicket,`}<br />{`Medical, Capability, Unspecified`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#group-id"
            }}>{`Group ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the parent training group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – 42e289346bf14ef68cd826ab86336e8b`}<br />{`If doesn’t exist – null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Group Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the parent training group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – Competency Group`}<br />{`If doesn’t exist – null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Default Issuing Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority responsible for issuing this Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – First Aid Australia`}<br />{`If doesn’t exist – null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Points`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of points allocated for holding this Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Competency code provided`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expires after - value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Competency expires after a certain duration (see next field)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expires after - unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed values: Days, Weeks, Months, Years`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Assigned To`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of employees record this competency is assigned to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      