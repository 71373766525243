import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Termination Method - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To identify if voluntary/Involuntary termination`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accepted values – By_Consent, By_Notice, `}<br />{`Summarily, By_Other_Means`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`End Date - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last day of working`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-09-01`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Terminated by`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Who actioned the termination`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kishore`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Termination Comments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any free text to record why the termination was made`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resignation by employee`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      