import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

const StyledTop = styled.div`
    width: 64px;
    height: 64px;
    background: ${({ theme }) => theme.colors.grey['90']};
    display: ${(props) => (props.isDisplay ? 'inline-block' : 'none')};
    position: fixed;
    right: 50px;
    bottom: 80px;
    cursor: pointer;
`;

const StyledImg = styled.img`
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

const Top = (props) => {
    const [isDisplay, setIsDisplay] = useState(false);

    const handleClick = () => {
        if (typeof window !== undefined) {
            document.documentElement.scrollTop = 0;
        }
    };

    const handleScroll = () => {
        if (typeof window !== undefined) {
            if (document.documentElement.scrollTop > window.innerHeight / 2) {
                setIsDisplay(true);
            } else if (isDisplay === true) {
                setIsDisplay(false);
            }
        }
    };

    useEffect(() => {
        if (typeof window !== undefined) {
            window.addEventListener('scroll', debounce(handleScroll, 200));
            return () => {
                window.removeEventListener('scroll', debounce(handleScroll, 200));
            };
        }
    });

    return (
        <StyledTop isDisplay={isDisplay} {...props} onClick={handleClick}>
            <StyledImg src="/images/icons/icon-arrow-up.svg" />
        </StyledTop>
    );
};

export default Top;
