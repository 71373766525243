import TableAccountId from "../../../../src/markdowns/sections/docs/accounts-and-branches/response-account-id.md";
import TableListBranches from "../../../../src/markdowns/sections/docs/accounts-and-branches/response-list-branches.md";
import TableRetrieveBranchDetails from "../../../../src/markdowns/sections/docs/accounts-and-branches/response-retrieve-branch-details.md";
import Message from "../../../../src/enb-styled/Message.js";
import * as React from 'react';
export default {
  TableAccountId,
  TableListBranches,
  TableRetrieveBranchDetails,
  Message,
  React
};