import Message from "../../../../src/enb-styled/Message.js";
import TableListAllCandidates from "../../../../src/markdowns/sections/docs/candidates/response-list-all-candidates.md";
import TableRetrieveCandidate from "../../../../src/markdowns/sections/docs/candidates/response-retrieve-candidate.md";
import TableCreateCandidate from "../../../../src/markdowns/sections/docs/candidates/response-create-candidate.md";
import TableUpdateCandidate from "../../../../src/markdowns/sections/docs/candidates/response-update-candidate.md";
import TableMergeCandidates from "../../../../src/markdowns/sections/docs/candidates/response-merge-candidates.md";
import TableConvertCandidate from "../../../../src/markdowns/sections/docs/candidates/response-convert-candidate-to-employee.md";
import * as React from 'react';
export default {
  Message,
  TableListAllCandidates,
  TableRetrieveCandidate,
  TableCreateCandidate,
  TableUpdateCandidate,
  TableMergeCandidates,
  TableConvertCandidate,
  React
};