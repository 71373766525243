// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-mdx": () => import("./../../../src/pages/contact-us.mdx" /* webpackChunkName: "component---src-pages-contact-us-mdx" */),
  "component---src-pages-development-actions-mdx": () => import("./../../../src/pages/development/actions.mdx" /* webpackChunkName: "component---src-pages-development-actions-mdx" */),
  "component---src-pages-development-authentication-mdx": () => import("./../../../src/pages/development/authentication.mdx" /* webpackChunkName: "component---src-pages-development-authentication-mdx" */),
  "component---src-pages-development-changelog-js": () => import("./../../../src/pages/development/changelog.js" /* webpackChunkName: "component---src-pages-development-changelog-js" */),
  "component---src-pages-development-get-started-mdx": () => import("./../../../src/pages/development/get-started.mdx" /* webpackChunkName: "component---src-pages-development-get-started-mdx" */),
  "component---src-pages-development-mdx": () => import("./../../../src/pages/development.mdx" /* webpackChunkName: "component---src-pages-development-mdx" */),
  "component---src-pages-development-openapi-description-mdx": () => import("./../../../src/pages/development/openapi-description.mdx" /* webpackChunkName: "component---src-pages-development-openapi-description-mdx" */),
  "component---src-pages-development-pagination-mdx": () => import("./../../../src/pages/development/pagination.mdx" /* webpackChunkName: "component---src-pages-development-pagination-mdx" */),
  "component---src-pages-development-scopes-mdx": () => import("./../../../src/pages/development/scopes.mdx" /* webpackChunkName: "component---src-pages-development-scopes-mdx" */),
  "component---src-pages-development-troubleshooting-mdx": () => import("./../../../src/pages/development/troubleshooting.mdx" /* webpackChunkName: "component---src-pages-development-troubleshooting-mdx" */),
  "component---src-pages-docs-accessing-our-api-mdx": () => import("./../../../src/pages/docs/accessing-our-api.mdx" /* webpackChunkName: "component---src-pages-docs-accessing-our-api-mdx" */),
  "component---src-pages-docs-accounts-and-branches-mdx": () => import("./../../../src/pages/docs/accounts-and-branches.mdx" /* webpackChunkName: "component---src-pages-docs-accounts-and-branches-mdx" */),
  "component---src-pages-docs-api-resources-accounts-mdx": () => import("./../../../src/pages/docs/api-resources-accounts.mdx" /* webpackChunkName: "component---src-pages-docs-api-resources-accounts-mdx" */),
  "component---src-pages-docs-api-resources-candidates-mdx": () => import("./../../../src/pages/docs/api-resources-candidates.mdx" /* webpackChunkName: "component---src-pages-docs-api-resources-candidates-mdx" */),
  "component---src-pages-docs-api-resources-employees-payroll-mdx": () => import("./../../../src/pages/docs/api-resources-employees-payroll.mdx" /* webpackChunkName: "component---src-pages-docs-api-resources-employees-payroll-mdx" */),
  "component---src-pages-docs-api-resources-employees-training-qualifications-mdx": () => import("./../../../src/pages/docs/api-resources-employees-training-qualifications.mdx" /* webpackChunkName: "component---src-pages-docs-api-resources-employees-training-qualifications-mdx" */),
  "component---src-pages-docs-apis-api-connectors-mdx": () => import("./../../../src/pages/docs/apis-api-connectors.mdx" /* webpackChunkName: "component---src-pages-docs-apis-api-connectors-mdx" */),
  "component---src-pages-docs-basics-api-registration-mdx": () => import("./../../../src/pages/docs/basics/api-registration.mdx" /* webpackChunkName: "component---src-pages-docs-basics-api-registration-mdx" */),
  "component---src-pages-docs-basics-introduction-mdx": () => import("./../../../src/pages/docs/basics/introduction.mdx" /* webpackChunkName: "component---src-pages-docs-basics-introduction-mdx" */),
  "component---src-pages-docs-business-case-mdx": () => import("./../../../src/pages/docs/business-case.mdx" /* webpackChunkName: "component---src-pages-docs-business-case-mdx" */),
  "component---src-pages-docs-candidates-mdx": () => import("./../../../src/pages/docs/candidates.mdx" /* webpackChunkName: "component---src-pages-docs-candidates-mdx" */),
  "component---src-pages-docs-employee-notes-mdx": () => import("./../../../src/pages/docs/employee-notes.mdx" /* webpackChunkName: "component---src-pages-docs-employee-notes-mdx" */),
  "component---src-pages-docs-employees-mdx": () => import("./../../../src/pages/docs/employees.mdx" /* webpackChunkName: "component---src-pages-docs-employees-mdx" */),
  "component---src-pages-docs-enablehr-api-mdx": () => import("./../../../src/pages/docs/enablehr-api.mdx" /* webpackChunkName: "component---src-pages-docs-enablehr-api-mdx" */),
  "component---src-pages-docs-enablehr-product-mdx": () => import("./../../../src/pages/docs/enablehr-product.mdx" /* webpackChunkName: "component---src-pages-docs-enablehr-product-mdx" */),
  "component---src-pages-docs-high-level-concepts-mdx": () => import("./../../../src/pages/docs/high-level-concepts.mdx" /* webpackChunkName: "component---src-pages-docs-high-level-concepts-mdx" */),
  "component---src-pages-docs-http-mdx": () => import("./../../../src/pages/docs/http.mdx" /* webpackChunkName: "component---src-pages-docs-http-mdx" */),
  "component---src-pages-docs-http-status-codes-mdx": () => import("./../../../src/pages/docs/http-status-codes.mdx" /* webpackChunkName: "component---src-pages-docs-http-status-codes-mdx" */),
  "component---src-pages-docs-introduction-to-enablehr-api-mdx": () => import("./../../../src/pages/docs/introduction-to-enablehr-api.mdx" /* webpackChunkName: "component---src-pages-docs-introduction-to-enablehr-api-mdx" */),
  "component---src-pages-docs-mdx": () => import("./../../../src/pages/docs.mdx" /* webpackChunkName: "component---src-pages-docs-mdx" */),
  "component---src-pages-docs-overview-index-page-mdx": () => import("./../../../src/pages/docs/overview/index-page.mdx" /* webpackChunkName: "component---src-pages-docs-overview-index-page-mdx" */),
  "component---src-pages-docs-overview-resources-rest-mdx": () => import("./../../../src/pages/docs/overview/resources-rest.mdx" /* webpackChunkName: "component---src-pages-docs-overview-resources-rest-mdx" */),
  "component---src-pages-docs-payroll-mdx": () => import("./../../../src/pages/docs/payroll.mdx" /* webpackChunkName: "component---src-pages-docs-payroll-mdx" */),
  "component---src-pages-docs-rest-resources-mdx": () => import("./../../../src/pages/docs/rest-resources.mdx" /* webpackChunkName: "component---src-pages-docs-rest-resources-mdx" */),
  "component---src-pages-docs-training-and-qualifications-mdx": () => import("./../../../src/pages/docs/training-and-qualifications.mdx" /* webpackChunkName: "component---src-pages-docs-training-and-qualifications-mdx" */),
  "component---src-pages-docs-what-is-a-connector-mdx": () => import("./../../../src/pages/docs/what-is-a-connector.mdx" /* webpackChunkName: "component---src-pages-docs-what-is-a-connector-mdx" */),
  "component---src-pages-docs-what-is-api-mdx": () => import("./../../../src/pages/docs/what-is-api.mdx" /* webpackChunkName: "component---src-pages-docs-what-is-api-mdx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-mdx": () => import("./../../../src/pages/partner.mdx" /* webpackChunkName: "component---src-pages-partner-mdx" */),
  "component---src-pages-release-notes-mdx": () => import("./../../../src/pages/release-notes.mdx" /* webpackChunkName: "component---src-pages-release-notes-mdx" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

