import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For Australian Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`BSB - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank State branch of the bank account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`094301`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Account Number - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank account number of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`123456789`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Account Name - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the person for the bank account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Linda Johnson`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dollar amount`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specified amount to be deposited`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If Remainder is chosen – Null value (or) the specified amount as per setup.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Statement Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text that appears on bank statement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Balance Salary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Remainder - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier to decide which bank accounts gets the remaining part of the salary credit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Account Order - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Order of how the bank account setup is to be considered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0 (or) 1 (or) 2 (or) 3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For New Zealand Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Bank Account Number - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank account number of the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`123456789`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Account Name - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the person for the bank account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Linda Johnson`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dollar amount`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specified amount to be deposited`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If Remainder is chosen – Null value (or) the specified amount as per setup.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Statement Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text that appears on bank statement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Balance Salary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-danger">{`Remainder - Mandatory`}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier to decide which bank accounts gets the remaining part of the salary credit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True / False`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      