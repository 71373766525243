import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name on the Screen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#role-id"
            }}>{`Role ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`c524532f5628418eb6c5146476e8d2f3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Store Manager`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of the Role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Competencies necessary for the Store Manager`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#group-id"
            }}>{`Parent Group Id`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the Parent Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – 082a674d0b314dee9e59f3ee1070c153`}<br />{`If doesn’t exist – null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent Group Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Parent Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If exists – Employee Roles group`}<br />{`If doesn’t exist – null`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      