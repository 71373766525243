import React from 'react';
import styled from 'styled-components';
import device from '../theme/device';

const StyledFooter = styled.footer`
    padding: 40px 0 20px 0;
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.grey['500']};
    @media ${device.mobile} {
        text-align: center;
    }
`;

const StyledFooterSocial = styled.div`
    text-align: right;
    a:not(:last-child) {
        margin-right: 12px;
    }
    @media ${device.mobile} {
        text-align: center;
        padding-top: 20px;
    }
`;

const Footer = () => (
    <StyledFooter>
        <div className="row">
            <div className="col-lg-6 col-md-6 col col-sm-12 col-xs-12">
                &copy; {new Date().getFullYear()} enableHR &middot; All rights reserved
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <StyledFooterSocial>
                    <a href="https://www.facebook.com/enableHRAU/" target="_blank" rel="noopener noreferrer">
                        <img src="/images/facebook.svg" alt="Facebook" />
                    </a>
                    <a href="https://www.linkedin.com/company/enablehr" target="_blank" rel="noopener noreferrer">
                        <img src="/images/linkedin.svg" alt="Linkedin" />
                    </a>
                    <a href="https://twitter.com/enableHR" target="_blank" rel="noopener noreferrer">
                        <img src="/images/twitter.svg" alt="Twitter" />
                    </a>
                </StyledFooterSocial>
            </div>
        </div>
    </StyledFooter>
);

export default Footer;
