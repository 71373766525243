import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name `}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#competency-id"
            }}>{`Competency ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`dc56c0e53854462a8cfa23e27e6c6c71`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Competency Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First Aid certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Issuing Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issuing Authority for the Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australian Training Institute`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Issuer Identifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issuer identity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Training Provider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date Completed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the Competency is completed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-08-31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expiry Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date the Competency expires`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2022-09-29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Points`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Points allocated for this Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Competency status for the Employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed Values: In_Progress, Completed, Inactive, New_Requirement, Missing,`}<br />{`Expired, Not_Applicable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#role-id"
            }}>{`Role ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of a Role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the Competency was allocated to the Employee via a role: c524532f5628418eb6c5146476e8d2f3`}<br />{`If not: Empty`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Role Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the Competency was allocated to the Employee via a role: Store Managers`}<br />{`If not: Empty`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      