import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`For Australian & New Zealand Accounts`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hours Per Week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Standard hours employee supposed to work in a week.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pay Rate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pay Rate at which an employee is by an hour/year.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42.14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rate Unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rate to choose if its by hourly/annually.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values - Hourly, Annually`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pay Schedule`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frequency at which employee is paid.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible Values - Weekly, Fortnightly, Monthly, Twice_Monthly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Payroll Company`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Free text to use for payroll processing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indigo Australia`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pay Group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Free text to use for payroll processing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fortnight hourly emps`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      