import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Name `}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Example`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Roles assigned to an Employee (array)`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#role-id"
            }}>{`Role ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of a Role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`f2fc5a50da4c456abe5ceaef512b7e9b`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Role Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Store Managers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="text-link"><strong parentName="td">{`Competencies assigned to an Employee (array)`}</strong></div></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#competency-id"
            }}>{`Competency ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of a Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`dc56c0e53854462a8cfa23e27e6c6c71`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Competency Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of a Competency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First Aid certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the Competency for the Employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed Values: In_Progress, Completed, Inactive,`}<br />{`New_Requirement, Missing, Expired,Not_Applicable`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      